<template>
  <div v-if="raceInfo.race">
    <span>
      【{{ raceInfo.result.grade }}】{{ raceInfo.result.ktitl }}
      {{ raceInfo.result.nj }}日目
    </span>
    <br />
    <span
      v-if="
        raceInfo.result.gcd === '0' ||
        raceInfo.result.gcd === '1' ||
        raceInfo.result.gcd === '2'
      "
      class="tyu"
      >※SG、GⅠ、GⅡはモグー加算の対象外となります。</span
    >
  </div>
  <div v-else>本日は非開催</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LastRace",
  computed: {
    ...mapGetters({
      raceInfo: "getRaceInfo",
    }),
  },
  beforeMount: function () {
    this.$store.dispatch("getLastRaceInfo");
  },
  mounted: function () {
    this.$setInterval(() => {
      this.$store.dispatch("getLastRaceInfo");
    }, 15000);
  },
};
</script>
<style scoped>
.tyu {
  width: 90%;
  margin: 1vw auto 0;
  font-size: 1.2vw;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .tyu {
    margin: 3vw auto 0;
    font-size: 2vw;
  }
}
</style>
