<template>
  <section class="bg_color">
    <h2>ランキングTOPICS</h2>
    <section class="inner">
      <div class="ribon_outline">
        <div class="ttl_ribon rank">
          <p class="ribon_inner"><Today /></p>
        </div>
      </div>
      <h3 class="ttl_main"><LastRace /></h3>

      <!--owner-->
      <div v-if="ownerInfo">
        <div class="box_lst slide rank">
          <h4 class="ttl_lst">
            <p class="inner crown">オーナーランキング</p>
          </h4>
          <ul class="lst">
            <li v-for="data in ownerInfo(rankingOwner)" :key="data.id">
              <p class="ribon_rank first">
                {{ data.rank }}<span class="small">位</span>
              </p>
              <div class="box_thum">
                <figure>
                  <img
                    :src="data.icon ? data.icon : Icon[0].img"
                    width=""
                    height=""
                    alt=""
                  />
                </figure>
                <p class="name">{{ data.nickname }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">現在までの獲得モグー</p>
                <p class="txt point">{{ addComma(data.point) }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">今節獲得モグー</p>
                <p class="txt point">{{ addComma(data.current_point) }}</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">登録モーター</p>
                <p class="txt">
                  <span
                    v-for="motors in data.motors"
                    :key="motors.index"
                    class="motor-num"
                    >{{ Number(motors.mno)
                    }}<span class="motor-dot">&#183;</span></span
                  >
                </p>
              </div>
              <div class="box_dtl">
                <router-link
                  :to="{ name: 'RankingOwnerDtl', params: { id: data.id } }"
                  class="btn_dtl btn_dtl--owner"
                  >詳細を見る</router-link
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="box_lnk">
          <router-link :to="{ name: 'RankingOwner' }" class="btn_lnk"
            >ランキングのつづきはこちら</router-link
          >
        </div>
      </div>

      <!--motor-->
      <div v-if="mogInfo">
        <div class="box_lst slide rank">
          <h4 class="ttl_lst">
            <p class="inner crown">モーター別 獲得モグー ランキング</p>
          </h4>
          <ul class="lst">
            <li
              v-for="data in mogInfo(rankingMotor.mog_ranking)"
              :key="data.mno"
            >
              <p class="ribon_rank first">
                {{ data.rank }}<span class="small">位</span>
              </p>
              <div class="box_thum">
                <figure class="round">
                  <img :src="motorImg[data.mno]" width="" height="" alt="" />
                </figure>
                <p class="name_large">
                  <span class="small">モーターNo.</span>{{ Number(data.mno) }}
                </p>
              </div>
              <div class="box_dtl">
                <p class="ttl none">現在までの獲得モグー</p>
                <p class="txt point">{{ addComma(data.point) }}</p>
              </div>
              <div class="box_dtl">
                <router-link
                  :to="{ name: 'MotorDtl', params: { id: data.mno } }"
                  class="btn_dtl btn_dtl--owner"
                  >詳細を見る</router-link
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="box_lnk">
          <router-link :to="{ name: 'RankingMotor' }" class="btn_lnk"
            >ランキングのつづきはこちら</router-link
          >
        </div>
      </div>

      <!--モーター別-->
      <section class="box_type_rank">
        <div class="rank_type" v-if="winInfo">
          <h4>
            <figure>
              <img
                src="@/assets/images/ranking/ico_ranking_1st.svg"
                width="90"
                height="114"
                alt="モーター別1着回数ランキング"
              />
            </figure>
            <p class="ttl">
              モーター別<br />1着回数<br class="visible_sp" />ランキング
            </p>
          </h4>
          <table>
            <tr>
              <th>順 位</th>
              <th>機 番</th>
              <th>1着回数</th>
            </tr>
            <tr
              v-for="data in winInfo(rankingMotor.win_ranking)"
              :key="data.mno"
            >
              <td class="rank first">
                {{ data.rank }}<span class="small">位</span>
              </td>
              <td>{{ Number(data.mno) }}</td>
              <td>{{ data.count }}<span class="small">回</span></td>
            </tr>
          </table>
        </div>
        <div class="rank_type" v-if="m2renInfo">
          <h4>
            <figure>
              <img
                src="@/assets/images/ranking/ico_ranking_moter.svg"
                width="82"
                height="116"
                alt="モーター別2連対率ランキング"
              />
            </figure>
            <p class="ttl">
              モーター別<br />2連対率<br class="visible_sp" />ランキング
            </p>
          </h4>
          <table>
            <tr>
              <th>順 位</th>
              <th>機 番</th>
              <th>2連対率</th>
            </tr>
            <tr
              v-for="data in m2renInfo(rankingMotor.m2ren_ranking)"
              :key="data.mno"
            >
              <td class="rank first">
                {{ data.rank }}<span class="small">位</span>
              </td>
              <td>{{ Number(data.mno) }}</td>
              <td>
                {{ orgFloor(data.m2ren, 10) }}<span class="small">%</span>
              </td>
            </tr>
          </table>
        </div>
        <!-- <div class="rank_type" v-if="winRatingInfo">
          <h4>
            <figure>
              <img
                src="@/assets/images/ranking/ico_ranking_winner.svg"
                width="103"
                height="114"
                alt="モーター別勝率ランキング"
              />
            </figure>
            <p class="ttl">
              モーター別<br />勝率<br class="visible_sp" />ランキング
            </p>
          </h4>
          <table>
            <tr>
              <th>順 位</th>
              <th>機 番</th>
              <th>勝 率</th>
            </tr>
            <tr v-for="data in winRatingInfo" :key="data.mno">
              <td class="rank first">
                {{ data.rank }}<span class="small">位</span>
              </td>
              <td>{{ Number(data.mno) }}</td>
              <td>
                {{ orgFloor(data.point, 100) }}<span class="small">%</span>
              </td>
            </tr>
          </table>
        </div> -->
      </section>

      <!--winner rank-->
      <section>
        <div class="box_lst rank" v-if="winRangkingInfo">
          <h4 class="ttl_lst">
            <p class="inner crown">モーター別 優勝回数 ランキング</p>
          </h4>
          <div class="box_rank_winner">
            <p class="scroll visible_sp">SCROLL</p>
            <div class="inner">
              <table>
                <tbody>
                  <tr>
                    <th>順 位</th>
                    <th>機 番</th>
                    <th>獲得モグー</th>
                    <th>優勝回数</th>
                    <th>優勝時の使用者</th>
                  </tr>
                  <tr
                    v-for="data in winRangkingInfo(rankingWin.motors)"
                    :key="data.mno"
                  >
                    <td class="rank first">
                      {{ data.rank }}<span class="small">位</span>
                    </td>
                    <td>{{ Number(data.mno) }}</td>
                    <td>
                      <span class="mog">{{
                        addComma(Number(data.point))
                      }}</span>
                    </td>
                    <td>{{ data.win_count }}<span class="small">回</span></td>
                    <td class="user">
                      <p v-for="racer in data.racers" :key="racer.racer">
                        {{ racer.racer
                        }}<span class="small"
                          >（{{ racer.kyu }}／{{ racer.ktitl }}
                          {{ racer.fdate }}～{{ racer.tdate }}）</span
                        >
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import Mixin from "@/mixins/mixin";
import LastRace from "@/components/LastRaceInfo.vue";
import Today from "@/components/Today.vue";
import { mapGetters } from "vuex";
export default {
  name: "RankingTopic",
  components: {
    LastRace,
    Today,
  },
  mixins: [Mixin],
  beforeMount: function () {
    this.$store.dispatch("rankingOwnerInfo");
    this.$store.dispatch("rankingMotorInfo");
    this.$store.dispatch("rankingWinInfo");
  },
  mounted: function () {
    this.$setInterval(() => {
      this.$store.dispatch("rankingOwnerInfo");
      this.$store.dispatch("rankingMotorInfo");
      this.$store.dispatch("rankingWinInfo");
    }, 15000);
  },
  computed: {
    ...mapGetters({
      rankingOwner: "getRankingOwnerInfo",
      rankingMotor: "getRankingMotorInfo",
      rankingWin: "getWinRankingInfo",
      motorImg: "getMotorImg",
      Icon: "getIcon",
    }),
  },
  methods: {
    orgFloor(val, base) {
      return Math.floor(val * base) / base;
    },
    ownerInfo(obj) {
      if (obj) obj = obj.slice(0, 6);
      return obj;
    },
    mogInfo(obj) {
      if (obj) obj = obj.slice(0, 6);
      return obj;
    },
    winInfo(obj) {
      if (obj) obj = obj.slice(0, 5);
      return obj;
    },
    m2renInfo(obj) {
      if (obj) obj = obj.slice(0, 5);
      return obj;
    },
    // winRatingInfo() {
    //   let obj = this.rankingMotor.win_rating_ranking;
    //   if (obj) obj = obj.slice(0, 5);
    //   return obj;
    // },
    winRangkingInfo(obj) {
      if (obj) obj = obj.slice(0, 5);
      return obj;
    },
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
